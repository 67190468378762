import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/chathistorypandit.css";
import useAuthStore from "../../Store/UserStore/userAuthStore";
import { useState } from "react";
import { useEffect } from "react";
import api from "../Axios/api";
import moment from 'moment';

function Chathistorypandits() {
  const navigate = useNavigate();

  const { user1 } = useAuthStore();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (user1?.id) {
        const fetchRequests = async () => {
            try {
                let type = "chat"
                // console.log(type,user1?.id);
                const response = await api.get(`/request/showforuser/${user1?.id}/${type}`);
                console.log(response.data, 'fgdgdgfdg')
                setRequests(response.data.data);
            } catch (err) {
                console.log('Failed to load requests', err);
            }
        };

        fetchRequests();
    }

}, [user1?.id]);

  const handleNavigation = (data,name,date) => {
    navigate('/chatshistoryuser', { state: { requestId: data ,name:name,date:date } });
  };

  return (
    <>
      <div className="chathistory_section">
        <div className="container">
          <h1 className="title">Users</h1>
          <div className="chatContainer">
            {requests.map((user) => (
              <button
                key={user.id}
                className="userCard"
                onClick={() => handleNavigation(user.request_id,user.name,user.updated_at)}
              >
              <p className="userName">{moment(user.updated_at).format('DD/MM/YYYY hh:mm A')}</p>  
                <p className="userName">{user.name} {user.lastname}</p>
                <p className="userName">{user.experience} Years</p>
                <p className="userName">{user.price} per min</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Chathistorypandits;
