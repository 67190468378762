import React, { useEffect, useState } from "react";
import "../../styles/productdetail.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import api from "../Axios/api";
import { TailSpin } from "react-loader-spinner";
import useAuthStore from "../../Store/UserStore/userAuthStore";
import CryptoJS from "crypto-js";
import useHomeStore from "../../Store/dataStore/homeStore";

const Productdetails = () => {
  let { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);
  const { user1 } = useAuthStore();
  const { isLoading } = useHomeStore();

  const decryptId = (encryptedIdFromUrl) => {
    const decodedId = decodeURIComponent(encryptedIdFromUrl);
    const bytes = CryptoJS.AES.decrypt(decodedId, "prabhupooja2024");
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const fetchProductData = async () => {
    const res = await api.get(`/products/get/${decryptId(productId)}`);
    console.log(res.data.data[0], "response");
    setProductData(res.data.data[0]);
    setLoading(false);
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  const handleBuyNow = () => {
    const totalPrice = quantity * productData.offerPrice || 0;

    if (!user1) {
      navigate("/");
    } else {
      navigate("/checkout", {
        state: {
          productId: decryptId(productId),
          quantity: quantity,
          totalPrice: totalPrice,
          user: user1,
          productData: productData,
        },
      });
    }
  };

  if (isLoading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5vh",
            marginTop: "50px",
          }}
        >
          <TailSpin height="50" width="50" color="orange" />
        </div>
        <p className="loading_text">Loading...</p>
      </>
    );
  }

  const stockStatus = productData.noOfItems > 0 ? "In Stock" : "Out of Stock";
  const isStockAvailable = productData.noOfItems > 0;

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <div className="product-img mb-5">
                <Link>
                  <img src={productData.image} alt="" />
                </Link>
              </div>
              <h1 className="product_title">
                Prabhu Pooja | {productData.productName}
              </h1>
              <p className="product_desc">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi,
                temporibus!
              </p>
              <div className="product">
                <div className="product-tabs">
                  <button className="product-link active">Details</button>
                </div>
              </div>
              <div className="product-content">
                <div className="product-pane active">
                  <div>
                    <span>Theme:</span>
                    <span> {productData.theme}</span>
                  </div>
                  <div>
                    <span>Brand:</span>
                    <span> {productData.brand}</span>
                  </div>
                  <div>
                    <span>Colour:</span>
                    <span> {productData.colour}</span>
                  </div>
                  <div>
                    <span>Style:</span>
                    <span> {productData.style}</span>
                  </div>
                  <div>
                    <span>Material:</span>
                    <span> {productData.material}</span>
                  </div>
                  <div>
                    <span>Special Feature:</span>
                    <span> {productData.specialFeature}</span>
                  </div>
                  <div>
                    <span>Number of Items: </span>
                    <span
                      className={`stock-status ${
                        isStockAvailable ? "in-stock" : "out-of-stock"
                      }`}
                    >
                      {stockStatus}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <form action="" className="package-form">
                <div
                  className="product-box"
                  style={{
                    background: "rgb(248, 117, 69)",
                    color: "#fff",
                    padding: "8px 8px",
                    marginBottom: "5px",
                  }}
                >
                  <h6 className="product_current_price">
                    <strong style={{ textDecoration: "line-through" }}>
                      RS.{productData.price}
                    </strong>
                    <strong>
                      {" "}
                      RS.{quantity * productData.offerPrice || 0}
                    </strong>
                  </h6>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    Quantity: <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="input"
                    required
                    min="1"
                    max="10"
                    defaultValue="1"
                    value={quantity}
                    onChange={(e) => {
                      const newQuantity = Math.max(
                        1,
                        Math.min(10, e.target.value)
                      );
                      setQuantity(newQuantity);
                    }}
                  />
                </div>

                <button
                  className="product-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleBuyNow();
                  }}
                  disabled={!isStockAvailable}
                >
                  Buy Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Productdetails;
