import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../Axios/api";
import useAuthStore from "../../Store/UserStore/userAuthStore";
import { IoSend } from "react-icons/io5";
import userimg from "../Assets/profile-pic.png";
import { MdOutlineCallEnd } from "react-icons/md";
import Swal from "sweetalert2";
import moment from 'moment';

const ChatScreen = () => {
  const { astrologerId, price, requestId } = useParams();
  const location = useLocation();
  const { uuid, pandit } = location.state || "";
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [mode, setMode] = useState(null);
  const [rating, setRating] = useState(0);
  const startTimeRef = useRef(null);
  const { user1 } = useAuthStore();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    handleModeGet();
  }, [mode, messages]);

  useEffect(() => {
    if (mode === 0) {
      handleEndChat();

    }
  }, [mode]);


  const handleModeGet = async () => {
    const response = await api.get(`/chats/userMode/${user1?.id}`);
    const hello = response?.data?.data?.chat_mode
    setMode(hello);
  }

  useEffect(() => {
    fetchMessages();
    fetchBalance();

    const interval = setInterval(fetchMessages, 5000);
    startTimeRef.current = Date.now();

    return () => {
      clearInterval(interval);
      // setTimeout(handleEndChat, 1000);
    };
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await api.get(`/chats/messages/${user1?.uuid}/${uuid}`);
      setMessages(response.data.messages);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setLoading(false);
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await api.get(`/users/balance/${user1?.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBalance(response.data.balance);
      console.log("Fetched balance:", response.data.balance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  const handleCancle = async () => {
    setShowFeedback(false)
    navigate('/');

  }

  const handleEndChat = async () => {
    try {
      console.log(user1.uuid, 'user', uuid, 'pandit')
      await api.post("/chats/chatEnd", {
        user_uuid: user1.uuid,
        pandit_uuid: uuid,
      });
      handleModeGet();

      await Swal.fire({
        title: "Chat Ended!",
        text: "Chat Ended successfully",
        icon: "info",
        confirmButtonText: "OK",
      });

      // Step 3: Calculate Duration and Cost
      const endTime = Date.now();
      const durationInMinutes = Math.ceil(
        (endTime - startTimeRef.current) / 60000
      );
      const totalCost = durationInMinutes * parseFloat(price);

      console.log("Total cost:", totalCost, "Duration:", durationInMinutes);

      // Step 4: Deduct Balance
      const numericBalance = parseFloat(user1?.balance);
      if (totalCost <= numericBalance) {
        const deductResponse = await api.post("/users/deductBalance", {
          userId: user1?.id,
          astrologerId,
          minutes: durationInMinutes,
        });

        if (deductResponse.data.success) {
          setBalance(deductResponse.data.newBalance);
          setShowFeedback(true);
        } else {
          await Swal.fire({
            title: "Failed!",
            text: "Failed to deduct balance: " + deductResponse.data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        await Swal.fire({
          title: "Insufficient Balance!",
          text: "Kripya apna wallet recharge karein.",
          icon: "warning",
          confirmButtonText: "Recharge Now",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      await Swal.fire({
        title: "Error!",
        text: "Error occurred while ending chat or deducting balance.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };


  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await api.post("/chats/messages/create", {
          senderId: user1?.uuid,
          receiverId: uuid,
          message: newMessage,
          request_id: requestId,
        });
        setNewMessage("");
        fetchMessages();
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      await api.post(`/panditComment/create/${pandit?.id}`, {
        name: user1?.name,
        email: user1?.email,
        rating: rating,
        comment: feedback,
      });

      await Swal.fire({
        title: "Feedback Submitted!",
        text: "Feedback submitted successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        setShowFeedback(false);
        navigate('/')
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      await Swal.fire({
        title: "Submission Failed!",
        text: "Something went wrong on the server.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };


  const handleStarClick = (star) => {
    setRating(star);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`star ${i <= rating ? "filled" : ""}`}
          onClick={() => handleStarClick(i)}
        >
          &#9733;
        </span>
      );
    }
    return stars;
  };


  const groupedMessages = messages.reduce((acc, msg) => {
    const date = moment(msg.timestamp).format('DD MMM YYYY'); 
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(msg);
    return acc;
  }, {});
  
  const today = moment().format('DD MMM YYYY'); 
  const yesterday = moment().subtract(1, 'days').format('DD MMM YYYY'); 
  
  


  return (
    <>
      <div className="chat-container">
        <div className="chat-header">
          <img src={pandit?.profileImage ? pandit.profileImage : userimg} alt="User" className="user-image" />
          <span className="user-name-chat">{pandit?.name}</span>

          <button className="end_btn" onClick={handleEndChat}>
            <MdOutlineCallEnd className="end-btn-icon" /> End
          </button>
        </div>

        <div className="chat-body">
          {loading ? (
            <div className="loader">Loading...</div>
          ) : (

            <div className="user-message">
              {Object.keys(groupedMessages).map((date, index) => (
                <div key={index} className="message-container">
                  <p style={{ width: "15%", textAlign: "center", padding: "5px 2px", borderRadius: "50px", fontSize: "15px", color: "white", display: "block", background: "gray" }}>
                    {date === today ? "Today" : date === yesterday ? "Yesterday" : date}
                  </p>

                  {groupedMessages[date].map((msg) => (
                    <div
                      key={msg.id}
                      className={`message-bubble ${user1?.uuid === msg.sender_id ? "sent" : "received"}`}
                    >
                      <p className="message-text">{msg.message}</p>
                      <span className="message-time">
                        {new Date(msg.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                  ))}

                </div>

              ))}
            </div>
          )}
        </div>

        <div className="chat-footer">
          <input
            type="text"
            className="text-input"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            onKeyDown={handleKeyPress}
          />
          <button className="send-button" onClick={handleSendMessage}>
            <IoSend className="send-btn-icon" />
          </button>
        </div>
      </div>
      {showFeedback && (
        <div className="feedback-popup">
          <div className="feedback-popup-content">
            <h2>Feedback</h2>

            <input
              type="text"
              placeholder="Your Name"
              value={user1?.name}

            />

            <input
              type="email"
              placeholder="Your Email"
              value={user1?.email}

            />

            <div className="star-rating">
              <label>Rating:</label>
              <div className="stars">{renderStars()}</div>
            </div>

            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please provide your feedback"
              rows="4"
            ></textarea>

            <button
              className="submit-feedback-btn feedbackbtn"
              onClick={handleFeedbackSubmit}
            >
              Submit
            </button>
            <button
              className="cancel-feedback-btn feedbackbtn"
              onClick={() => handleCancle()}
            >
              Skip
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatScreen;
