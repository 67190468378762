import React, { useEffect, useState } from "react";
import "../../styles/blog.css";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import useHomeStore from "../../Store/dataStore/homeStore";
import moment from "moment";
import CryptoJS from "crypto-js";

function truncateText(text, wordLimit) {
  if (!text) return "";
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
}

function Blog() {
  // const [blogPosts, setBlogPosts] = useState([]);
  // const [loading, setLoading] = useState(true);
  const {loading,gettinyblog, tinybloglist } =
    useHomeStore();
  const { isLoading } = useHomeStore();
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    const response = await gettinyblog();
    setBlogList(response.data.data);
  };

  const encryptId = (blogId) => {
    const encrypted = CryptoJS.AES.encrypt(
      blogId.toString(),
      "prabhupooja2024"
    ).toString();
    return encodeURIComponent(encrypted);
  };

  // useEffect(() => {
  //     const fetchBlogs = async () => {
  //         try {
  //             const response = await api.get('/blog/get');
  //             const data = response.data;
  //             console.log(data);
  //             if (data.success) {
  //                 setBlogPosts(data.blogs);
  //             } else {
  //                 console.error('Failed to fetch blogs:', data.message);
  //             }
  //         } catch (error) {
  //             console.error('Error fetching blogs:', error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };

  //     fetchBlogs();
  // }, []);

  // console.log(tinybloglist.timestamp, "kjlkjkj");

  if (isLoading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "5vh",
            marginTop: "50px",
          }}
        >
          <TailSpin height="50" width="50" color="orange" />
        </div>
        <p className="loading_text">Loading...</p>
      </>
    );
  }

  return (
    <>
      <div className="sub_header_blog">
        <div className="container">
          <div className="subheader_inner_blog">
            <div className="subheader_text_blog">
              <h1>Spiritual - Blogs</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active">Blogs</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="blog-container">
      <div className="blog-layout">
  {tinybloglist && tinybloglist?.length > 0 ? (
    tinybloglist?.map((post) => {
      let firstImage = post.image;
      const encryptedId = encryptId(post.id);

      return (
        <div className="post-card" key={post.id}>
          <Link
            to={`/blog/${post.title.replace(/\s+/g, "-")}/${encryptedId}`}
          >
            <div
              className="post-image"
              style={{
                backgroundImage: `url(${firstImage || "default-image-url.jpg"})`,
              }}
            ></div>
          </Link>
          <div className="post-content">
            <h2 className="post-title">{post.title || "Untitled Blog"}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  post.pera
                    .replace(/<img[^>]*>/g, "")
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .split(" ")
                    .slice(0, 25)
                    .join(" ") + " ...",
              }}
            ></div>

            <div className="author_name_time">
              <p>By: Prabhu Pooja</p>
              <p className="blog_time">
                {moment(post.timestamp).fromNow()}
                </p>
            </div>

            <Link
              to={`/blog/${post.title.replace(/\s+/g, "-")}/${encryptedId}`}
              className="read-more1"
            >
              <span>Read More</span>
            </Link>
          </div>
        </div>
      );
    })
  ) : (
    
    <div className="post-card">No Blog Found</div>
  )}
      </div>

      </div>
    </>
  );
}

export default Blog;
