import { create } from 'zustand';
import api from "../../Components/Axios/api";
import axios from 'axios';

const useUserStore = create((set) => ({
    productCount: 0,
    templeCount: 0,
    prasadCount: 0,
    yogaCount: 0,
    yogaData: [],
    orders: [],
    isLoading: false,

    setProductCount: (value) => set({ productCount: value }),

    getAuthHeaders: () => {
        const token = localStorage.getItem("token"); 
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
    },

    userFetchProduct: async (userId) => {
        set({ isLoading: true });
        try {
            const response = await api.get(`/orders/getby/${userId}`, useUserStore.getState().getAuthHeaders());
            set({ productCount: response.data.data.orderCount });
            return response.data;
        } catch (error) {
            console.error("Failed to fetch products:", error);
        } finally {
            set({ isLoading: false });
        }
    },

    userfetchTempleBookings: async (userId) => {
        set({ isLoading: true });
        try {
            const response = await api.get(`/temple/user/${userId}`, useUserStore.getState().getAuthHeaders());
            set({ templeCount: response.data.count });
            return response;
        } catch (error) {
            console.error("Failed to fetch temple:", error);
        } finally {
            set({ isLoading: false });
        }
    },

    userfetchPrasadBooking: async (userId) => {
        set({ isLoading: true });
        try {
            const response = await api.get(`/user/prasad/getuser/${userId}`, useUserStore.getState().getAuthHeaders());
            set({ prasadCount: response.data.prasadCount });
            return response;
        } catch (error) {
            console.error("Failed to fetch prasad:", error);
        } finally {
            set({ isLoading: false });
        }
    },

    userfetchYogaBooking: async (userId) => {
        set({ isLoading: true });
        try {
            const response = await api.get(`/yoga/getuser/${userId}`, useUserStore.getState().getAuthHeaders());
            set({ yogaCount: response.data.count, yogaData: response.data.data });
            return response;
        } catch (error) {
            console.error("Failed to fetch yoga:", error);
        } finally {
            set({ isLoading: false });
        }
    },

    userOrdersFetchByOrderId: async (orderId) => {
        set({ isLoading: true });
        try {
            const response = await api.get(`/orders/getbyuser/${orderId}`, useUserStore.getState().getAuthHeaders());
            set({ orders: response.data.products });
            return response;
        } catch (error) {
            console.error("Failed to fetch orders:", error);
        } finally {
            set({ isLoading: false });
        }
    },

    getUserCityByPincode: async (postalCode) => {
        set({ isLoading: true });
        try {
            const response = await axios.get(`https://api.zippopotam.us/IN/${postalCode}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return null;
        } finally {
            set({ isLoading: false });
        }
    },

}));

export default useUserStore;
