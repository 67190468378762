import React, { useEffect } from "react";
import "./OrderTracking.css";
import { FaCheckCircle } from "react-icons/fa";
import { FaExclamationTriangle } from "react-icons/fa";
import useAuthStore from "../../Store/UserStore/userAuthStore";
import { useState } from "react";

const OrderTracking = () => {
  const {user1} = useAuthStore();

  const [mapUrl,setMapUrl] = useState("")

  const orderData = {
    orderPlaced: "14 Jan",
    total: 500,
    customerName: "Nikhile",
    orderId: 1008,
    status: "Shipped",
    estimatedDelivery: "17 Jan - 19 Jan",
    trackingSteps: [
      { name: "Order Placed", date: "14 Jan", status: "tracking-completed" },
      { name: "Dispatched", date: "14 Jan", status: "tracking-completed" },
      { name: "Shipping", date: "16 Jan", status: "tracking-in-progress" },
      { name: "Delivered", date: "17 Jan - 19 Jan", status: "tracking-error" } // ERROR Step
    ],
    shippingAddress: "123 Indrapuri Colony Indore",
    courier: "Blue Dark",
    mapUrl: "https://www.google.com/maps/embed?...",
  };

  const getMapUrl = async (address) => {
    const encodedAddress = encodeURIComponent(address);
    const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json`);
    const data = await response.json();
    
  
    if (data.length > 0) {
      const { lat, lon } = data[0];
  
      const lonLeft = parseFloat(lon) - 0.01;
      const lonRight = parseFloat(lon) + 0.01;
      const latBottom = parseFloat(lat) - 0.01;
      const latTop = parseFloat(lat) + 0.01;
  
      return `https://www.openstreetmap.org/export/embed.html?bbox=${lonLeft},${latBottom},${lonRight},${latTop}&layer=mapnik&marker=${lat},${lon}`;
    }
  
    return null; 
  };


  useEffect(()=>{
      if(user1){
        const cityParts = user1?.city.split(" ");
        const userShipingAddress = user1?.address + " "+ cityParts[0]
          //  console.log(user1, userShipingAddress);

getMapUrl(userShipingAddress).then((mapUrl) => {
  // console.log("Generated Map URL:", mapUrl);
  setMapUrl(mapUrl);
});
      }
  },[user1])





  if (!orderData) {
    return <p>Loading order details...</p>;
  }

  return (
    <div className="order-tracking-container">
      <h2 className="title">ORDER TRACKING</h2>

      {/* Order Summary */}
      <div className="order-tracking-summary">
        <div className="summary-tracking-item">
          <span>ORDER PLACED</span>
          <strong>{orderData.orderPlaced}</strong>
        </div>
        <div className="summary-tracking-item">
          <span>TOTAL</span>
          <strong>{orderData.total} rs</strong>
        </div>
        <div className="summary-tracking-item">
          <span>SHIP TO</span>
          <strong>{user1?.name}</strong>
        </div>
        <div className="summary-tracking-item">
          <span>ORDER</span>
          <strong>#{orderData.orderId}</strong>
        </div>
      </div>

      {/* Order Status */}
      <div className="order-tracking-status">
        <h3>
          Order Status: <span className={`status ${orderData.status.toLowerCase()}`}>{orderData.status}</span>
        </h3>
        <p>Estimated Delivery: <strong>{orderData.estimatedDelivery}</strong></p>

        <div className="progress-tracking-bar">
          {orderData.trackingSteps.map((step, index) => (
            <div key={index} className={`tracking-step ${step.status}`}>
              {/* Icon logic */}
              {step.status === "tracking-completed" && <FaCheckCircle className="icon success" />}
              {step.status === "tracking-in-progress" && <FaCheckCircle className="icon in-progress" />}
              {step.status === "tracking-error" && <FaExclamationTriangle className="icon error" />}
              
              <span>{step.name}</span>
              <small>{step.date}</small>
            </div>
          ))}
        </div>
      </div>

      {/* Shipping Information */}
      <div className="shipping-info">
        <h3>SHIPPING INFORMATION</h3>
        <p><strong>Name:</strong> {user1?.name}</p>
        <p><strong>Address:</strong> {user1?.address}{" "}{user1?.city}</p>
        <p><strong>Country:</strong> {user1?.country}</p>
      </div>

      {/* Google Map */}
      <div className="map-container">
        <iframe
          title="Google Map"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          src={mapUrl}
        ></iframe>
      </div>
    </div>
  );
};

export default OrderTracking;
