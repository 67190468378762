import React, { useEffect, useRef, useState } from "react";
import logo from "../Components/Assets/PRABHU POOJA LOGO1.png";
import { Link, useNavigate } from "react-router-dom";
import prasadimg from "../Components/Assets/prasadimg.webp";
import userprofileimg from "../Components/Assets/profileimg.png";
import "../styles/navbar.css";
import useAuthStore from "../Store/UserStore/userAuthStore";
import useUserStore from "../Store/UserStore/userStore";
import useUserCardStore from "../Store/userCardStore/userCardStore";
import useOnlinePujaStore from "../Store/PoojaStore/OnlinePoojaStore";
import useProblemPoojaStore from "../Store/ProblemPoojaStore/ProblemPoojaStore";
import { IoCartOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import GoogleTranslate from "../GoogleTranslate";
import Login from "../Components/login/Login";
import OtpPopup from "./otp/Otp";
import Signup from "./signup/Signup";
import useHomeStore from "../Store/dataStore/homeStore";
import CryptoJS from "crypto-js";

function Navbar() {
  const menuRef = useRef(null);
  const openMenuBtnRef = useRef(null);
  const closeMenuBtnRef = useRef(null);
  const cartRef = useRef(null);
  const profileMenuRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
  const [isThirdDropdownOpen, setIsThirdDropdownOpen] = useState(false);
  const [isFourthDropdownOpen, setIsFourthDropdownOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [image, setImage] = useState("");
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  // const [isPrimeMember, setIsPrimeMember] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [product, setProduct] = useState(0);
  const [balance, setBalance] = useState(0);
  // const [cartItems, setCartItems] = useState([]);
  const [prasad, setPrasad] = useState(0);
  const [yoga, setYoga] = useState(0);
  const [cartDropdownOpen, setCartDropdownOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [temple, setTemple] = useState(0);
  const navigate = useNavigate();
  const { isLoggin, user1, setIsMember, isMember } = useAuthStore();
  const {
    userFetchProduct,
    productCount,
    userfetchTempleBookings,
    templeCount,
    userfetchPrasadBooking,
    prasadCount,
    userfetchYogaBooking,
    yogaCount,
    setProductCount,
  } = useUserStore();
  const { getUserPujaBookings } = useOnlinePujaStore();
  const { getBookings } = useProblemPoojaStore();
  const { cartItems, getCartItems } = useUserCardStore();
  const [probemPoojaCount, setProbemPoojaCount] = useState(0);
  const [onlinePoojaCount, setOnlinePoojaCount] = useState(0);
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const [isOtpPopup, setIsOtpPopup] = useState(false);
  const [isSingupPopup, setIsSingupPopup] = useState(false);
  const [loginInput, setLoginInput] = useState("");
  const { getOnlinePuja, getServices, services } = useHomeStore();
  const [onlinePoojaName, setOnlinePujaName] = useState([]);


  const openPopup = () => {
    setIsLoginPopup(true);
    setIsSingupPopup(false);
    setIsOtpPopup(false);
  };
  const closePopup = () => {
    setIsLoginPopup(false);
  };

  const openOtpPopup = () => {
    setIsOtpPopup(true);
    setIsLoginPopup(false);
    setIsSingupPopup(false);
  };
  const closeOtpPopup = () => {
    setIsOtpPopup(false);
  };

  const openSingPopup = () => {
    setIsSingupPopup(true);
    setIsOtpPopup(false);
    setIsLoginPopup(false);
  };

  const closeSingPopup = () => {
    setIsSingupPopup(false);
  };

  useEffect(() => {
    fetchOnlinePooja();
  }, []);

  const fetchOnlinePooja = async () => {
    const response = await getOnlinePuja();
    if (response.data.success) {
      setOnlinePujaName(response.data.data);
    }
  };

  useEffect(() => {
    if (isLoggin) {
      const handleLoginStatusChange = () => {
        setIsLoginPopup(false);
      };

      window.addEventListener("loginStatusChanged", handleLoginStatusChange);

      return () => {
        window.removeEventListener(
          "loginStatusChanged",
          handleLoginStatusChange
        );
      };
    }
  }, [isLoggin]);

  // logOut
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("member");
    setUserName("");
    setUserMobile("");
    setProfileMenuOpen(false);
    navigate("/");
    window.location.reload();
    window.dispatchEvent(new Event("loginStatusChanged"));
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }
    };

    if (profileMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [profileMenuOpen]);

  const handleItemClick = (action) => {
    action();
    setProfileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuOpen &&
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }

      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }

      if (
        cartDropdownOpen &&
        cartRef.current &&
        !cartRef.current.contains(event.target)
      ) {
        setCartDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuOpen, isMenuOpen, cartDropdownOpen]);

  const handleViewCartClick = () => {
    if (!user1) {
      navigate("/login");
      setIsCartModalOpen(false);
    } else {
      navigate("/cart");
      setIsCartModalOpen(false);
    }
  };

  const handleProfileToggle = () => {
    setProfileMenuOpen((prev) => !prev);
    if (isMenuOpen) setMenuOpen(false);
    if (cartDropdownOpen) setCartDropdownOpen(false);
  };

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
    if (profileMenuOpen) setProfileMenuOpen(false);
    if (cartDropdownOpen) setCartDropdownOpen(false);
  };

  const handleSecondDropdownToggle = () =>
    setIsSecondDropdownOpen(!isSecondDropdownOpen);

  const handleThirdDropdownToggle = () =>
    setIsThirdDropdownOpen(!isThirdDropdownOpen);

  const handleFourthDropdownToggle = () =>
    setIsFourthDropdownOpen(!isFourthDropdownOpen);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
    if (profileMenuOpen) setProfileMenuOpen(false);
    if (cartDropdownOpen) setCartDropdownOpen(false);
  };

  const handleLinkClick = () => {
    setDropdownOpen(false);
    setIsSecondDropdownOpen(false);
    setIsThirdDropdownOpen(false);
    setIsFourthDropdownOpen(false);
    setMenuOpen(false);
  };

  const handleEcommerceBookingClick = () => {
    navigate("/ecomerceookingpage");
    setMenuOpen(false);
  };

  const handlePrasadBookingClick = () => {
    navigate("/prasadbookingpage");
  };

  const handleTempleBookingClick = () => {
    navigate("/templebookingpage");
  };

  const handleYogaBookingClick = () => {
    navigate("/yogabookingpage");
  };

  const handlePoojaBookingClick = () => {
    navigate("/poojabooking");
  };

  const handleEditProfile = () => {
    navigate("/editprofile");
  };

  const handleChatUsers = () => {
    navigate("/chathistorypandits");
  };

  useEffect(() => {
    if (user1) {
      fetchProduct();
      fetchTempleBookings();
      userfetchPrasad();
      fetchYoga();
      fetchOnlinePoojaCount();
      fetchProblemPoojaCount();
      fetchSerives();
    }
  }, [user1]);

  const fetchCartItems = async () => {
    await getCartItems(user1?.id);
  };

  useEffect(() => {
    if (isLoggin && user1) {
      const timer = setTimeout(() => {
        fetchCartItems();
      }, 10);

      return () => clearTimeout(timer);
    }
  }, [isLoggin, user1]);

  const fetchProblemPoojaCount = async () => {
    const response = await getBookings(user1?.id);
    if (response.data.success) {
      setProbemPoojaCount(response.data.data.length);
    } else {
      setProbemPoojaCount(0);
    }
  };

  const fetchOnlinePoojaCount = async () => {
    const response = await getUserPujaBookings(user1?.id);
    if (response.data?.success) {
      setOnlinePoojaCount(response.data?.data?.length);
    } else {
      setOnlinePoojaCount(0);
    }
  };

  const fetchProduct = async () => {
    const response = await userFetchProduct(user1?.id);
    if (response.success) {
      setProductCount(response.data.orderCount);
    }
  };

  const fetchTempleBookings = async () => {
    await userfetchTempleBookings(user1?.id);
  };

  // prasad fetching bookings
  const userfetchPrasad = async () => {
    await userfetchPrasadBooking(user1?.id);
  };

  // yoga fetching bookings
  const fetchYoga = async () => {
    await userfetchYogaBooking(user1?.id);
  };

  // cardItmes fetching bookings

  // const fetchCartItems =  async() => {
  //   await getCartItems(user1?.id);
  //   console.log(cartItems, "5");
  // };

  // temple fetching bookings

  // const fetchBookings = async () => {
  //   try {
  //     const response = await api.get(`temple/user/${user1?.id}`);
  //     const data = response.data.count;
  //     if (data) {
  //       setTemple(data);
  //     } else {
  //       setTemple([]);
  //     }
  //   } catch (err) {
  //     console.error("Error fetching temple bookings:", err);
  //   }
  // };

  // useEffect(() => {
  //   if(isLoggin && user1){
  //     fetchBookings();
  //   }
  // },[isLoggin]);

  // useEffect(() => {
  //   if(isLoggin && user1){
  //   fetchPrasad();
  //   };

  // },[isLoggin]);

  // useEffect(() => {
  //   if(isLoggin){
  //     fetchYoga();
  // }
  // },[]);

  const handleBalanceClick = () => {
    navigate("/recharge");
  };

  useEffect(() => {
    if (user1) {
      if (user1.member === 1) {
        setIsMember(true);
      } else {
        setIsMember(false);
      }
    }
  }, [user1]);

  const formatURL = (str) => str.toLowerCase().replace(/\s+/g, "");
  const encryptId = (blogId) => {
    const encrypted = CryptoJS.AES.encrypt(
      blogId.toString(),
      "prabhupooja2024"
    ).toString();
    return encodeURIComponent(encrypted);
  };



  const fetchSerives = async () => {
    await getServices();
  }

  console.log(services, "services");

  return (
    <div className={`header ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className={`menu ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
          <div className="head">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <button
              type="button"
              className="close-menu-btn"
              ref={closeMenuBtnRef}
              onClick={toggleMenu}
            ></button>
          </div>
          <ul>
            <li>
              <Link to="/" onClick={handleLinkClick}>
                Home
              </Link>
            </li>

            {/* <li>
              <Link to="/about">About us</Link>
            </li> */}

            <li className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleDropdownToggle}>
                Services
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleDropdownToggle}
              ></i>

              {/* <ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/membership" onClick={handleLinkClick}>
                    <span>Membership</span>
                  </Link>
                </li>
                <li>
                  <Link to="/onlinepooja" onClick={handleLinkClick}>
                    <span>Online Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/prasaddelivery" onClick={handleLinkClick}>
                    <span>Prasad Delivery</span>
                  </Link>
                </li>
                <li>
                  <Link to="/astrology" onClick={handleLinkClick}>
                    <span>Astrology</span>
                  </Link>
                </li>
                <li>
                  <Link to="/muhurat" onClick={handleLinkClick}>
                    <span>Muhurat</span>
                  </Link>
                </li>
                <li>
                  <Link to="/e-commerce" onClick={handleLinkClick}>
                    <span>E-Commerce</span>
                  </Link>
                </li>
                <li>
                  <Link to="/temple" onClick={handleLinkClick}>
                    <span>Temple</span>
                  </Link>
                </li>
                <li>
                  <Link to="/pandit" onClick={handleLinkClick}>
                    <span>Pandit</span>
                  </Link>
                </li>
                <li>
                  <Link to="/yoga" onClick={handleLinkClick}>
                    <span>Yoga</span>
                  </Link>
                </li>
              </ul> */}

<ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
  {services.map((service, index) => {
        const path = `/${service.name.toLowerCase().replace(/\s+/g, "")}`;
    return(
    
    <li key={index}>
      <Link to={path} onClick={handleLinkClick}>
        <span>{service.name}</span>
      </Link>
    </li>
  )})}
</ul>
              
            </li>

            <li className={`dropdown ${isSecondDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleSecondDropdownToggle}>
                Online Pooja
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleSecondDropdownToggle}
              ></i>
              <ul
                className={`sub-menu ${isSecondDropdownOpen ? "active" : ""}`}
              >
                {onlinePoojaName &&
                  onlinePoojaName?.map((pooja) => {
                    const encryptedId = encryptId(pooja.id);
                    return (
                      <li key={pooja.id}>
                        <Link
                          to={`/${formatURL(pooja.name)}/${encryptedId}`}
                          onClick={handleLinkClick}
                        >
                          <span>{pooja.name}</span>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </li>

            {/* <li className={`dropdown ${isThirdDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleThirdDropdownToggle}>
                Product
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleThirdDropdownToggle}
              ></i>
              <ul className={`sub-menu ${isThirdDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/e-commerce" onClick={handleLinkClick}>
                    <span>Special Product</span>
                  </Link>
                </li>
                <li>
                  <Link to="/e-commerce" onClick={handleLinkClick}>
                    <span>Vastu Product</span>
                  </Link>
                </li>
                <li>
                  <Link to="/e-commerce" onClick={handleLinkClick}>
                    <span>Pooja Kits</span>
                  </Link>
                </li>
                <li>
                  <Link to="/e-commerce" onClick={handleLinkClick}>
                    <span>Yantra</span>
                  </Link>
                </li>
                <li>
                  <Link to="/e-commerce" onClick={handleLinkClick}>
                    <span>Rudraksh</span>
                  </Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/blogs" onClick={handleLinkClick}>
                Blog
              </Link>
            </li>

            <li className={`dropdown ${isFourthDropdownOpen ? "active" : ""}`}>
              <Link to="/ourteam" onClick={handleFourthDropdownToggle}>
                Brand Info
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleFourthDropdownToggle}
              ></i>
              <ul
                className={`sub-menu ${isFourthDropdownOpen ? "active" : ""}`}
              >
                <li>
                  <Link to="/ourteam" onClick={handleLinkClick}>
                    <span>Our Team</span>
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={handleLinkClick}>
                    <span>About Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/enquiryform" onClick={handleLinkClick}>
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/testimonial" onClick={handleLinkClick}>
                    <span>Testimonial</span>
                  </Link>
                </li>
                <li>
                  <Link to="/faq" onClick={handleLinkClick}>
                    <span>FAQ</span>
                  </Link>
                </li>
                <li>
                  <Link to="/disclaimer" onClick={handleLinkClick}>
                    <span>Disclaimer</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={handleLinkClick}>
                    <span>Events</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/enquiryform">Contact Us</Link>
            </li> */}
          </ul>
        </div>

        <div className="header-right">
          <button
            type="button"
            className="icon-btn"
            onClick={() => {
              setIsCartModalOpen((prev) => !prev);
              if (profileMenuOpen) setProfileMenuOpen(false);
              if (isMenuOpen) setMenuOpen(false);
            }}
          >
            {user1 && <i className="fa-solid fa-cart-plus"></i>}
            {cartItems?.length > 0 && (
              <span className="cart-item-count">{cartItems.length}</span>
            )}
          </button>

          {/* {cartDropdownOpen && (
            <div className="cart-dropdown" ref={cartRef}>
              <h3>Cart Items</h3>
              <ul className="cart-items-list">
                {cartItems.length > 0 ? (
                  cartItems.map((item, index) => (
                    <li key={index} className="cart-dropdown-item">
                      <img
                        src={item.image || prasadimg}
                        alt={item.productName}
                      />
                      <div className="cart-item-details">
                        <p>{item.productName}</p>
                        <p>₹ {item.price}</p>
                        <p>Quantity: {item.quantity}</p>
                      </div>
                    </li>
                  ))
                ) : (
                  <p>No items in cart</p>
                )}
              </ul>
              <button className="view-cart-btn" onClick={handleViewCartClick}>
                View Cart
              </button>
            </div>
          )} */}

          {isCartModalOpen && (
            <div className="cart-modal">
              <div className="cart-modal-content">
                <h3 className="cartmodal-heading">Cart Items</h3>
                <button
                  className="close-cart-modal-btn"
                  onClick={() => setIsCartModalOpen(false)}
                >
                  &times;
                </button>
                <ul className="cart-items-list">
                  {cartItems?.length > 0 ? (
                    cartItems?.map((item, index) => (
                      <li key={index} className="cart-modal-item">
                        <img
                          src={item.image || prasadimg}
                          alt={item.productName}
                        />
                        <div className="cart-item-details">
                          <p>{item.productName}</p>
                          <p>₹ {item.offerPrice}</p>
                          <p>Quantity: {item.quantity}</p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <>
                      <div className="cartBox">
                        <IoCartOutline className="cartIcon" />
                        <p className="cartText">No items in cart</p>
                      </div>
                    </>
                  )}
                </ul>
                <button className="view-cart-btn" onClick={handleViewCartClick}>
                  View Cart
                </button>
              </div>
            </div>
          )}

          {isLoggin ? (
            <div className="profile-menu" ref={profileMenuRef}>
              <img
                src={user1?.image || userprofileimg}
                alt="User"
                className="userimg"
                onClick={handleProfileToggle}
              />
              {profileMenuOpen && (
                <div className="profile-dropdown">
                  <img
                    src={user1?.image || userprofileimg}
                    alt="User"
                    className="userimg1"
                    onClick={handleProfileToggle}
                  />
                  <p className="user-name">
                    {user1.name}{" "}
                    {isMember && (
                      <span style={{ fontSize: "14px", marginTop: "2px" }}>
                        💎
                      </span>
                    )}
                    <FaEdit
                      className="edit_icon"
                      onClick={() => handleItemClick(handleEditProfile)}
                    />
                  </p>
                  <p className="user-mobile">{user1.mobile}</p>

                  <div className="separator"></div>
                  <p
                    className="user-balance"
                    onClick={() => handleItemClick(handleBalanceClick)}
                  >
                    Wallet balance: ₹ {user1?.balance}
                  </p>
                  <p
                    className="user-booking"
                    onClick={() => handleItemClick(handleEcommerceBookingClick)}
                  >
                    Ecommerce Booking:
                    <span> {productCount}</span>
                  </p>
                  <p
                    className="user-booking"
                    onClick={() => handleItemClick(handlePrasadBookingClick)}
                  >
                    Prasad Booking:
                    <span> {prasadCount} </span>
                  </p>
                  <p
                    className="user-booking"
                    onClick={() => handleItemClick(handleTempleBookingClick)}
                  >
                    Temple Booking:
                    <span> {templeCount} </span>
                  </p>
                  <p
                    className="user-booking"
                    onClick={() => handleItemClick(handleYogaBookingClick)}
                  >
                    Yoga Booking: {yogaCount}
                  </p>
                  <p
                    className="user-booking"
                    onClick={() => handleItemClick(handlePoojaBookingClick)}
                  >
                    Pooja Booking: {probemPoojaCount + onlinePoojaCount || 0}
                  </p>
                  <p className="user-booking" onClick={() => handleChatUsers()}>
                    Chat History
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>
              {/* <Link to="/login"> */}
              <button className="login-btn" onClick={openPopup}>
                Login
              </button>
              {/* </Link> */}
            </>
          )}

          <a
            href="https://play.google.com/store/apps/details?id=com.prabhupooja"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="download-btn">Get App</button>
          </a>

          <GoogleTranslate />

          <button
            type="button"
            className="open-menu-btn"
            ref={openMenuBtnRef}
            onClick={toggleMenu}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>
        </div>
      </div>

      {isLoginPopup && (
        <Login
          onCloseLogin={closePopup}
          onOpenOtp={openOtpPopup}
          onOpenSignup={openSingPopup}
          setLoginInput={setLoginInput}
        />
      )}
      {isOtpPopup && (
        <OtpPopup closeOtpPopup={closeOtpPopup} inputOTP={loginInput} />
      )}

      {isSingupPopup && (
        <Signup closeSingClose={closeSingPopup} onOpenLogin={openPopup} />
      )}
    </div>
  );
}

export default Navbar;
